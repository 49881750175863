import React from 'react';
import {
  feature1text,
  feature1title,
  feature2text,
  feature2title,
  feature3text,
  feature3title,
  feature4text,
  feature4title,
  feature5text,
  feature5title,
  feature6text,
  feature6title,
  featuretitle,
} from "../components/texts";
import FeatureCard from "./featurecard/feature_card";
import ora from "../assets/phone_home.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Features() {
  useEffect(() => {
    Aos.init({ duration: 300, once: true });
  }, []);
  return (
    <div className="px-12 mb-12 text-center text-[#fffefe] bg-[#1d2527]">
      <div className="mb-10">
        <h1 className="text-4xl font-black text-primary" data-aos="fade-up">
          {featuretitle}
        </h1>
      </div>
      <div className=" md:flex">
        <div className=" md:w-1/3 ">
          <FeatureCard title={feature1title} description={feature1text} />
          <FeatureCard title={feature2title} description={feature2text} />
          <FeatureCard title={feature3title} description={feature3text} />
        </div>
        <div className="hidden md:flex md:justify-center md:items-center">
          <div className=" md:w-1/2 " data-aos="fade-up">
            <img src={ora} alt="ora app features" />
          </div>
        </div>
        <div className=" md:w-1/3 ">
          <FeatureCard title={feature4title} description={feature4text} />
          <FeatureCard title={feature5title} description={feature5text} />
          <FeatureCard title={feature6title} description={feature6text} />
        </div>
      </div>
    </div>
  );
}

export default Features;
