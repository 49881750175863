import React from 'react';
import { useEffect } from "react";
import Features from "./sections/feature";
import Footer from "./sections/footer";
import Header from "./sections/header";
import HowItWorks from "./sections/howitworks";
import LandingPage from "./sections/landing";
import Overview from "./sections/overview";
import ScrollToTop from "./sections/scrolltop";

function App() {
  // Function to observe sections and add animation classes
  const addAnimation = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-fadeIn"); // Add animation class
        observer.unobserve(entry.target); // Stop observing once animation is applied
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(addAnimation, {
      threshold: 0.1, // Trigger animation when 10% of the section is visible
    });

    // Select all sections except the Header
    const sections = document.querySelectorAll("section:not(#header)"); // Exclude header

    sections.forEach((section) => observer.observe(section));

    return () => {
      // Cleanup observer on component unmount
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div className="text-lg">
      <Header />
      <section id="home" className="opacity-0 transition-opacity duration-1000">
        <LandingPage />
      </section>
      <section
        id="overview"
        className="opacity-0 transition-opacity duration-1000"
      >
        <Overview />
      </section>
      <section
        id="features"
        className="opacity-0 transition-opacity duration-1000"
      >
        <Features />
      </section>
      <section
        id="how-it-works"
        className="opacity-0 transition-opacity duration-1000"
      >
        <HowItWorks />
      </section>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
