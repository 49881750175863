import React from 'react';
import {
  Hiw1text,
  Hiw1title,
  Hiw2text,
  Hiw2title,
  Hiw3text,
  Hiw3title,
  Hiw5text,
  Hiw5title,
  HiwTitle,
} from "../components/texts";
import HiwCard from "./howitworkscard/how_it_works_card";
import suggest from "../assets/suggest.png";
import scan from "../assets/scan.png";
import vt from "../assets/vt.png";
import shopping from "../assets/shopping.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function HowItWorks() {
  useEffect(() => {
    Aos.init({ duration: 300, once: true });
  }, []);
  return (
    <div className=" text-center px-12 bg-[#1d2527] text-[#fffefe]">
      <div>
        <div>
          <div className="md:px-12 md:py-10 ">
            <h1 className="text-4xl text-primary font-black" data-aos="fade-up">
              {HiwTitle}
            </h1>
          </div>
          <div
            className=" md:py-16 flex flex-col justify-center items-center md:items-start md:flex-row-reverse"
            data-aos="fade-up"
          >
            <HiwCard title={Hiw1title} description={Hiw1text} />
            <div className="w-2/5 flex justify-center items-center ">
              <img
                className=" md:h-[70vh] "
                src={suggest}
                alt="ora app features"
              />
            </div>
          </div>
        </div>
        <div
          className=" my-16 flex flex-col justify-center items-center md:items-start md:my-40 md:flex-row"
          data-aos="fade-up"
        >
          <HiwCard title={Hiw2title} description={Hiw2text} />
          <div className=" w-2/5 flex justify-center items-center ">
            <img className=" md:h-[70vh] " src={scan} alt="ora app features" />
          </div>
        </div>
        <div
          className=" my-16 flex flex-col justify-center items-center md:items-start md:py-20 md:flex-row-reverse"
          data-aos="fade-up"
        >
          <HiwCard title={Hiw3title} description={Hiw3text} />
          <div className=" w-2/5 flex justify-center items-center ">
            <img className=" md:h-[70vh] " src={vt} alt="ora app features" />
          </div>
        </div>
        <div
          className=" my-16 flex flex-col justify-center items-center md:items-start md:pt-20 md:flex-row"
          data-aos="fade-up"
        >
          <HiwCard title={Hiw5title} description={Hiw5text} />
          <div className=" w-2/5 flex justify-center items-center ">
            <img
              className=" md:h-[70vh] "
              src={shopping}
              alt="ora app features"
            />
          </div>
        </div>
      </div>
      <section className="py-12 px-6 md:p-24 flex justify-center items-center">
        <div className=" bg-gradientTheme py-24 px-4 md:px-24 max-w-screen-lg text-white rounded-3xl  flex flex-col justify-center items-center">
          <h1 className="text-3xl md:text-5xl font-bold text-center leading-tight">
            Stop searching and start deciding.
          </h1>
          <p className="my-4 text-lg">
            Bring your shopping to life with Tai Ora!
          </p>
          <a
            href="http://waitwhile.com/locations/taioraai"
            className="bg-primary uppercase text-white rounded-full px-10 py-4 text-sm mt-5 flex flex-row"
          >
            Join Beta
          </a>
        </div>
      </section>
    </div>
  );
}

export default HowItWorks;
