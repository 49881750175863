export const Hometitle = "Experience the Tai Ora";
export const Hometxt =
  "Tai Ora is an innovative new app that brings products to life through video and information. Stop searching and start deciding. Bring your shopping to life with Tai Ora!";
export const JoinBeta = "Join Beta";

// overview
export const overviewtitle = "Overview";
export const overviewtext =
  "Introducing a smarter way to shop. Tired of choice paralysis and information overload? Tai Ora is here to help. Scan a barcode, QR code, image or search and Tai Ora will deliver relevant videos and information to help you make the right purchace decision. Tai Ora simplifies shopping by providing the product information you need most - on demand and in seconds. No more aimlessly searching through overwhelming product options.";

// Features
export const featuretitle = "Features";
export const feature1title = "Scan to Play";
export const feature1text =
  "Scan barcodes, QR codes, product images to pull up products content most relevant to individual conditions.";
export const feature2title = "Search to Play";
export const feature2text =
  "Search products easily by name or description to pull curated product content.";
export const feature3title = "Intelligent Video Playback";
export const feature3text =
  "Our machine learning algorithms intelligently analyze your search criteria and play only that portion of the video most relevant to your request.";
export const feature4title = "Easy Compare";
export const feature4text =
  "Access key product specifications and details to easily compare products side-by-side.";
export const feature5title = "AI Recommendations";
export const feature5text =
  "Our machine learning algorithms analyze user behavior and preferences, providing personalized recommendations for content, features or actions within the app.";
export const feature6title = "Natural Language Processing";
export const feature6text =
  "Integrate a conversational AI chatbot powered by NLP, allowing users to interact with the app using natural language for a more intuitive and user-friendly experience.";

// How it works
export const HiwTitle = "How it works";
export const Hiw1title = "SmartSuggest AI";
export const Hiw1text =
  "SmartSuggest AI anticipates customer needs, delivering tailor-made recommendations. Powered by machine learning, this feature analyzes customer behavior and profile data, offering suggestions for content, features or actions within the app.";
export const Hiw2title = "Context AI";
export const Hiw2text =
  "SmartSuggest AI intelligently processes scanned data input to retrieve product and product video data, specifically suited for the context within which the scan takes place, e.g. how to use an appliance, or how to assemble the appliance.";
export const Hiw3title = "Video Trim AI";
export const Hiw3text =
  "SmartSuggest AI intelligently trims product videos to short segments relevant to the search criteria minimizing the amount of information to deal with.";
export const Hiw5title = "Integrated Shopping Experience";
export const Hiw5text =
  "Tai Ora allows you to instantly purchase reviewed products to save on time shopping online and in stores.";
