import React from 'react';
import { useState, useEffect } from "react";
import { JoinBeta } from "../components/texts";
import logo from "../assets/logo.svg";

function Header() {
  const [navbar, setNavbar] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  // Store the header height for scroll offset
  useEffect(() => {
    const header = document.getElementById("header");
    if (header) {
      setHeaderHeight(header.offsetHeight);
    }

    const changeNavBg = () => {
      if (window.scrollY >= 50) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener("scroll", changeNavBg);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("scroll", changeNavBg);
  }, []);

  // Function to handle smooth scrolling to the section with offset
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -headerHeight; // Negative value to account for header height
    const yPosition =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: yPosition, behavior: "smooth" });
  };

  return (
    <header
      id="header"
      className={
        "text-[#fffefe] fixed z-50 top-0 left-0 w-full flex justify-between px-5 items-center transition-all duration-300 " +
        (navbar ? "text-[#fffefe] bg-[#1d2527] py-2" : "bg-transparent py-5") // Change background when scrolling
      }
    >
      <div className="flex flex-row justify-center items-center">
        <img className="h-16" src={logo} alt="Ora ai logo" />
      </div>
      <nav className=" hidden md:flex ">
        <ul className="flex">
          <li
            className=" mx-4 cursor-pointer font-semibold "
            onClick={() => scrollToSection("home")}
          >
            Home
          </li>
          <li
            className=" mx-4 cursor-pointer font-semibold "
            onClick={() => scrollToSection("overview")}
          >
            Overview
          </li>
          <li
            className=" mx-4 cursor-pointer font-semibold "
            onClick={() => scrollToSection("features")}
          >
            Features
          </li>
          <li
            className=" mx-4 cursor-pointer font-semibold "
            onClick={() => scrollToSection("how-it-works")}
          >
            How it works
          </li>
        </ul>
      </nav>
      <div
        className={
          navbar
            ? "bg-primary text-white p-1 px-4 rounded-full cursor-pointer transition-all duration-300"
            : "bg-black text-white p-2 px-4 rounded-full cursor-pointer transition-all duration-300"
        }
      >
        <a href="http://waitwhile.com/locations/taioraai">{JoinBeta}</a>
      </div>
    </header>
  );
}

export default Header;
